












































































import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import StringConversions from '@/mixins/StringConversions'
import { Api } from '@/edshed-common/api'
import { QuizSubjectInfo, TableState } from '@/edshed-common/api/types'
import Modal from '@/edshed-common/components/Modal.vue'
import AddSubject from './components/AddSubject.vue'

@Component({
  components: {
    Modal,
    AddSubject
  }
})
export default class QuizSubjects extends mixins(StringConversions) {
  subjectData: QuizSubjectInfo[] = []
  response: string = ''
  loading: boolean = false
  subjectTableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'text',
    dir: 'asc',
    term: ''
  }

  editingSubject: QuizSubjectInfo | null = null
  showAddSubjectModal: boolean = false

  get isAdmin () {
    return (this.$store.state.user.school.admin === 1)
  }

  mounted () {
    this.$nextTick(() => {
      this.getSubjectData()
    })
  }

  async getSubjectData () {
    try {
      this.subjectData = await Api.getQuizSubjects()
    } catch (err) {
      console.log(err)
    }
  }

  addSubjectClicked () {
    this.showAddSubjectModal = true
    this.editingSubject = null
  }

  subjectAdded (subject: QuizSubjectInfo) {
    console.log('added aubject')
    this.getSubjectData()
  }

  editSubjectClicked (subject: QuizSubjectInfo) {
    this.showAddSubjectModal = true
    this.editingSubject = subject
  }

  deleteSubjectClicked (subject: QuizSubjectInfo) {
    if (subject.quiz_count > 0) {
      this.$buefy.dialog.confirm({
        title: 'Deleting subject',
        message: `This subject has <b>${subject.quiz_count} ${subject.quiz_count > 1 ? 'quizzes' : 'quiz'}</b> attached to it. ${subject.quiz_count > 1 ? 'These quizzes' : 'This quiz'} will lose the <b>${subject.text}</b> subject tag if you continue.`,
        confirmText: 'Delete Subject',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => this.doDeleteSubject(subject)
      })
    } else {
      this.doDeleteSubject(subject)
    }
  }

  async doDeleteSubject (subject: QuizSubjectInfo) {
    try {
      await Api.deleteQuizSubject(subject.id)

      this.$buefy.toast.open({
        message: 'Subject deleted',
        type: 'is-success'
      })

      this.getSubjectData()
    } catch (err) {
      console.log(err)
    }
  }
}
