


























import { Component, Vue, Prop } from 'vue-property-decorator'
import { Api, QuizSubjectInfo } from '@/edshed-common/api'

@Component({ components: {} })
export default class AddSubject extends Vue {
  subjectTitle: string = ''

  @Prop({ default: null }) readonly subject!: QuizSubjectInfo | null

  public mounted () {
    if (this.subject) {
      this.subjectTitle = this.subject.text
    }
  }

  public async submit () {
    if (this.subjectTitle.trim().length < 2) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Subject title must be 2 characters or more',
        position: 'is-bottom',
        type: 'is-danger'
      })
      return
    }

    if (this.subject) {
      try {
        const subject = await Api.editQuizSubject(this.subject.id, { text: this.subjectTitle })
        this.$emit('close')
        this.$emit('subject-created', subject)
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        const subject = await Api.addQuizSubject({ text: this.subjectTitle })
        this.$emit('close')
        this.$emit('subject-created', subject)
      } catch (err) {
        console.log(err)
      }
    }
  }

  public close () {
    this.$emit('close')
  }

  get isEdit () {
    return this.subject !== null
  }
}
