import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class StringConversions extends Vue {
  getNumberGameTitle (key) {
    if (key == null) {
      return ''
    }
    const bits = key.split('_')
    let name = ''
    switch (bits[0]) {
    case 'numberBonds':
      name = 'Number Bonds'
      break
    case 'timesTables':
      name = 'Times Tables'
      break
    case 'powerTen':
      name = 'Powers of 10'
      break
    case 'powerTenDecimals':
      name = 'Powers of 10 (Decimals)'
      break
    case 'addSubtract':
      name = 'Add & Subtract'
      break
    case 'more':
      name = 'More'
      break
    default:
      return ''
    }
    if (bits.length > 1) {
      name += ' (' + bits[1] + ')'
    }

    if (bits.length > 2) {
      name += ' - ' + bits[2].charAt(0).toUpperCase() + bits[2].slice(1)
    }

    return name
  }

  getNumberGameTypeKey (key) {
    if (key == null) {
      return ''
    }
    const bits = key.split('_')
    return bits[0]
  }
}
